import {NODE_ENV, PAGES_REVALIDATE_TIME, STATIC_MODE} from 'config/index';
import {GroupTypesMappingContext} from 'context/GroupTypesMappingContext';
import {StationsContext} from 'context/StationsContext';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {getLocalePrefix} from 'hooks/useLocalePrefix';
import {GetServerSideProps, GetStaticPaths, InferGetServerSidePropsType} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useEffect, useMemo} from 'react';
import {QueryClient, dehydrate} from 'react-query';

import {getApiV1GroupsMapping, getApiV1StationsByCity} from 'lib/api/backend';
import {GetApiV1GroupsMapping200, Station} from 'lib/api/backend.schemas';
import {Homepage, Page, PageResponseDataObject, getHomepage, getPagesId} from 'lib/api/strapi';

import {createBuildCache, createStaticCache, prefetchQueries} from 'utils/caching';
import {DefaultPagePropsProvider, TDefaultProps} from 'utils/dataFetching/DefaultPagePropsProvider';
import {getAllPagesStatic, getHomepageAllLocalesStatic} from 'utils/dataFetching/fetchStaticPages';
import getDefaultPageProps from 'utils/dataFetching/getDefaultPageProps';
import {getLocaleSlug} from 'utils/generatedStations/slugTranslations';
import {cityStationData, stationData} from 'utils/generatedStations/stationGenerationData';
import {setCurrentLocale} from 'utils/translations/setCurrentLocale';

import Layout from 'components/layout-components/Layout/Layout';
// import Layout from 'components/layout/MainLayout/MainLayout';
import {TTranslatedSlugs} from 'components/layout-components/Navbar/types';
import Seo from 'components/strapi-components/Seo';

// import { writeFileSync } from 'fs';
import getComponent from '../utils/strapi-components/getComponent';
import {translationFiles} from '../utils/translations/constants';

const DynamicPage: InferGetServerSidePropsType<typeof getStaticProps> = ({
  page,
  categoryTypeMapping,
  translatedSlugs,
  stations,
  defaultProps,
}: {
  page: Page;
  categoryTypeMapping: GetApiV1GroupsMapping200;
  translatedSlugs: any; //TTranslatedSlugs;
  defaultProps: TDefaultProps;
  stations: Station[];
}) => {
  const router = useRouter();
  const {t} = useTranslation('common');

  useEffect(() => {
    if (!page && !defaultProps) {
      router.replace('/404/');
    }
  }, []);
  const components = useMemo(() => page?.content?.map(getComponent(page)) || [], [page]);
  if (!page && !defaultProps) {
    return null;
  }
  if (!page) {
    return (
      <DefaultPagePropsProvider defaultProps={defaultProps}>
        <StationsContext.Provider value={stations}>
          {/* <Layout translatedSlugs={translatedSlugs}> */}
          <h2>{t('untreatedError')}</h2>
          {/* </Layout> */}
        </StationsContext.Provider>
      </DefaultPagePropsProvider>
    );
  }
  let published = true;
  if (!page?.publishedAt) {
    published = false;
  }

  return (
    <DefaultPagePropsProvider defaultProps={defaultProps}>
      <StationsContext.Provider value={stations}>
        <GroupTypesMappingContext.Provider value={categoryTypeMapping}>
          <Layout
            published={published}
            hideBPR={page.slug === 'index'}
            translatedSlugs={translatedSlugs}
          >
            <div style={{zIndex: 1}}>
              <Seo seoData={page.seo || undefined} />
              <Head>
                {/* {metaTags} */}
                <title>{page?.seo?.metaTitle || page.title}</title>
              </Head>
              {components}
            </div>
          </Layout>
        </GroupTypesMappingContext.Provider>
      </StationsContext.Provider>
    </DefaultPagePropsProvider>
  );
};
export const getStaticPaths: GetStaticPaths = async ({locales}) => {
  const pages = await getAllPagesStatic();
  // create paths for all Pages with according slugs
  const paths = locales
    .filter(loc => loc !== 'default')
    .reduce(
      (acc, next) => [
        ...acc,
        ...[...pages.data, {attributes: {fullSlug: '', slug: '', locale: next}}]
          .filter(page => page?.attributes.locale === next)
          .map(({attributes: {fullSlug, slug, locale}}) => ({
            params: {
              slug: fullSlug ? fullSlug.split('/') : [slug],
            },
            locale,
          })),
      ],
      [],
    );
  // const pages = ([] as PageListResponse) || (await getAllPagesStatic());
  // // create paths for all Pages with according slugs
  // const paths = [] || locales;
  if (process.env.NEXT_PUBLIC_DISABLE_PAGES) {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }
  return {
    paths,
    fallback: 'blocking',
  };
};
export const getStaticProps: GetServerSideProps = async ({
  params,
  locale,
  locales,
  defaultLocale,
}) => {
  setCurrentLocale(locale);
  const slug = params.slug as string[];
  const categoryTypeMapping = await getApiV1GroupsMapping();
  const queryClient = new QueryClient();
  let stations: Station[];
  const uniquePageIdentifier = `${slug ? slug.join('-') : ''}-${locale}`;
  const uniquePageAllLocaleIdentifier = `${slug ? slug.join('-') : ''}-all`;
  let initialPage: Page & Homepage;
  try {
    // @ts-ignore because classes were generated weirdly from orval
    let response: {
      data: {
        attributes: Page & Homepage;
      };
    };
    // if no slug present its the Homepage, so use Homepage Data
    let res;
    try {
      try {
        // Handle Station Pages that are automatically genereated, if one found for url it still looks in strapi if a page for that slug exists, and returns that. if station not found & page not found 404 is returned. if station found and strapi not it returns default station page
        if (slug?.[0] === getLocaleSlug('slugPart1', locale) && slug.length > 1) {
          const stationCity =
            slug?.[1].includes(`${getLocaleSlug('slugPart2', locale)}-`) &&
            slug?.[1]?.replace(`${getLocaleSlug('slugPart2', locale)}-`, '');
          const stationName = slug?.[2]?.replace(/-\d\d\d?/gi, '');
          // console.log(stationCity);
          let city;
          try {
            city = await createBuildCache(
              `station-array-${stationCity}-${locale}`,
              async () => await getApiV1StationsByCity(stationCity),
            );
          } catch (err) {
            console.log('Station not Found ', stationCity);
            if (process.env.npm_lifecycle_event.includes('build')) {
              return {
                notFound: true,
                props: {
                  page: null,
                  defaultProps: await getDefaultPageProps({locale, locales}),
                  stations,
                  ...(await serverSideTranslations(locale, translationFiles)),
                },
                revalidate: 30,
              };
            }
            return {
              redirect: {
                destination: `${getLocalePrefix({
                  locale,
                  defaultLocale,
                  slash: 'front',
                })}/${getLocaleSlug('slugPart1', locale)}`,
                permanent: false,
                // statusCode: 301
              },
              revalidate: 30,
            };
          }
          if (stationCity && !stationName) {
            stations = city;
            // @ts-ignore because classes were generated weirdly from orval
            response = cityStationData({stationCity, city, locale, getLocaleSlug, locales});
          } else if (stationCity && stationName) {
            const singleStation = city.filter(station => station?.descriptionSlug === stationName);
            stations = singleStation.length ? singleStation : city;
            if (singleStation?.length) {
              // @ts-ignore because classes were generated weirdly from orval
              response = stationData({
                singleStation,
                stationCity,
                stationName,
                locale,
                getLocaleSlug,
                locales,
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
        if (process.env.npm_lifecycle_event === 'build') {
          return {
            notFound: true,
            props: {
              page: null,
              defaultProps: await getDefaultPageProps({locale, locales}),
              stations,
              ...(await serverSideTranslations(locale, translationFiles)),
            },
            revalidate: 30,
          };
        }
        return {
          redirect: {
            destination: `${getLocalePrefix({
              locale,
              defaultLocale,
              slash: 'front',
            })}/${getLocaleSlug('slugPart1', locale)}`,
            permanent: false,
            // statusCode: 301
          },
          revalidate: 30,
        };
      }
      let fallbackPage;
      try {
        if (slug) {
          // Check if there is a Page for any locale for this particular slug so we can redirect to its localized page
          fallbackPage = await createStaticCache(uniquePageAllLocaleIdentifier, async () => {
            const fallback = await getPagesId(encodeURIComponent(slug.join('/')), {
              locale: 'all',
            });

            return fallback;
          });
        }
      } catch (err) {
        console.log('no page in any locale for ', {slug, locale});
        if (slug?.[0] === 'de-DE') {
          console.log('deprecated locale requested, sending 404');
          return {
            notFound: true,
            props: {
              page: null,
              defaultProps: await getDefaultPageProps({locale: defaultLocale, locales}),
              stations,
              ...(await serverSideTranslations(defaultLocale, translationFiles)),
            },
            revalidate: 30,
          };
        }
      }
      let pageRes;
      let redirect: {redirect: boolean; url: string};
      try {
        pageRes = await createStaticCache(uniquePageIdentifier, async () => {
          if (!slug) {
            const homepage = await getHomepageAllLocalesStatic();
            res = await getHomepage({locale} as unknown);
          } else {
            res = await getPagesId(encodeURIComponent(slug.join('/')), {locale});
            if (STATIC_MODE && NODE_ENV === 'production' && !res?.data?.attributes?.publishedAt) {
              res = undefined;
            }
          }
          return res;
        });
      } catch (err) {
        // handle the case if there is no page for the current locale
        try {
          if (fallbackPage) {
            // if there is a page in another locale for this slug, and it has a translation for the requested locale, create a redirect object with the url to the translated page
            let fallbackLocalePage;
            if (
              fallbackPage &&
              fallbackPage?.data?.attributes?.localizations?.data.length &&
              (
                fallbackPage?.data?.attributes?.localizations
                  ?.data[0] as unknown as PageResponseDataObject
              )?.attributes?.locale === locale
            ) {
              fallbackLocalePage = fallbackPage?.data?.attributes?.localizations
                ?.data[0] as unknown as PageResponseDataObject;
              redirect = {
                redirect: true,
                url: `${
                  fallbackLocalePage?.attributes?.locale === defaultLocale
                    ? ''
                    : `/${fallbackLocalePage?.attributes?.locale}`
                }/${fallbackLocalePage?.attributes?.fullSlug}`,
              };
            }
          } else {
            throw err;
          }
        } catch (err2) {
          throw err;
        }
      }
      if (redirect?.redirect) {
        // if there is a redirect object - redirect , at build time there is a need to return 404, with revalidation
        console.log(`fallback redirect from ${slug?.join('/')} to ${redirect.url}`);
        if (process.env.npm_lifecycle_event === 'build') {
          return {
            notFound: true,
            props: {
              page: null,
              defaultProps: await getDefaultPageProps({locale, locales}),
              stations,
              ...(await serverSideTranslations(locale, translationFiles)),
            },
            revalidate: 30,
          };
        }
        return {
          redirect: {
            destination: redirect.url,
            permanent: false,
            // statusCode: 301
          },
          revalidate: 30,
        };
      }
      if (pageRes) {
        response = pageRes;
      }
    } catch (err) {
      console.log(err);
      if (!response) {
        // console.log("second if")
        if (slug?.[0] === getLocaleSlug('slugPart1', locale)) {
          if (process.env.npm_lifecycle_event === 'build') {
            return {
              notFound: true,
              props: {
                page: null,
                defaultProps: await getDefaultPageProps({locale, locales}),
                stations,
                ...(await serverSideTranslations(locale, translationFiles)),
              },
              revalidate: 30,
            };
          }
          return {
            redirect: {
              destination: `${getLocalePrefix({
                locale,
                defaultLocale,
                slash: 'front',
              })}/${getLocaleSlug('slugPart1', locale)}`,
              permanent: false,
              // statusCode: 301
            },
            revalidate: 30,
          };
        }
        throw err;
      }
    }
    initialPage = response.data?.attributes;
  } catch (err) {
    // if an error occures above, send a 404 Page
    return {
      notFound: true,
      props: {
        page: null,
        defaultProps: await getDefaultPageProps({locale, locales}),
        stations,
        ...(await serverSideTranslations(locale, translationFiles)),
      },
      revalidate: 30,
    };
  }
  const page = initialPage;
  // creat translated slugs
  // @ts-ignore
  const translatedSlugs: TTranslatedSlugs = [
    {locale: initialPage?.locale, slug: initialPage?.fullSlug || ''},
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...(initialPage?.localizations.data as unknown as {attributes: Page}[]).map(
      ({attributes: {fullSlug = '', locale}}) => ({
        slug: fullSlug,
        locale,
      }),
    ),
  ];
  // Prefetch React-Query Queries for Components Specified in components/strapi-components/index.tsx prefetchQueryMapping
  // so the QueryClient Can be Dehydrated with the according data in cache when sent to frontend
  await prefetchQueries(page.content, queryClient);
  const response = {
    props: {
      page: page || null,
      translatedSlugs,
      categoryTypeMapping,
      defaultProps: await getDefaultPageProps({locale, locales}),
      stations: stations || null,
      ...(await serverSideTranslations(locale, translationFiles)),
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: PAGES_REVALIDATE_TIME,
    // STATIC_MODE === 'false' ? 2 : STATIC_MODE === 'true' ? false : 2,
  };
  return response;
};

export default DynamicPage;
